<template>
  <div>
    <suspense>
      <template #default>
        <ProductInfo />
      </template>
      <template #fallback>
        <div />
      </template>
    </suspense>
  </div>
</template>
<script>
import { ref, computed, toRef, inject, provide, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import ProductInfo from '@/components/front/product/detail/Info.vue'
export default {
  name: 'Detail',
  components: {
    ProductInfo
  },
  props: {
    productID: {
      type: String,
      default: ''
    }
  },
  async setup (props) {
    const { getters, dispatch } = useStore()
    const { push } = useRouter()

    const setAlert = inject('setAlert')

    const memberID = computed(() => getters['member/getMemberID'])
    const specList = computed(() => getters['product/getProductSpecList'])
    const payways = computed(() => getters['payment/getPayways'])
    const shipServices = computed(() => getters['order/getShipServices'])
    const cartMount = computed(() => getters['cart/getCartMount'])

    const productID = toRef(props, 'productID') || ''

    const number = ref(1)
    const selectedSpec = ref({})

    const readProduct = async () => {
      const payload = {
        qty: number.value,
        productID: productID.value,
        memberID: memberID.value
      }

      if (memberID.value != null) {
        payload.memberID = memberID.value
      }
      try {
        const response = await dispatch('product/readProduct', { payload })
        if (response.data.code === 200) {
          if (!selectedSpec.value?.specID) {
            selectedSpec.value = specList.value.find((item) => item.storageQty > 0) || specList.value?.[0] || {}
          }
        } else {
          setAlert(true, false, '查無此商品')
          push('/product/list')
        }
      } catch (error) {
        setAlert(true, false, error)
        push('/product/list')
      }
    }

    const readPayways = async () => {
      const payload = {}

      try {
        await dispatch('payment/readPayways', { payload })
      } catch (error) {
        setAlert(true, false, error)
      }
    }

    const readShipService = async () => {
      const payload = { offset: 0, pageSize: 10 }

      try {
        await dispatch('order/readShipServices', { payload })
      } catch (error) {
        // console.log('get user info error: ' + ', ' + error)
        setAlert(true, false, error)
      }
    }

    watch([productID, cartMount], async (oldValue, newValue) => {
      const oldproductID = oldValue[0]
      const newproductID = newValue[0]
      const oldCartMount = oldValue[1]
      const newCartMount = newValue[1]

      if (oldproductID !== newproductID || oldCartMount < newCartMount) {
        await readProduct()
        if (selectedSpec.value.specID) {
          selectedSpec.value = specList.value.find((item) => item.specID === selectedSpec.value.specID)
        }
      }
    })

    provide('selectedSpec', selectedSpec)
    provide('readProduct', readProduct)
    provide('number', number)
    provide('productID', productID)

    await readProduct()
    if (payways.value.length === 0) {
      await readPayways()
    }
    if (shipServices.value.length === 0) {
      await readShipService()
    }

    return {

    }
  }
}
</script>
<style lang="scss" module>

</style>
